import {
  CheckWithPrefixResponse,
  PREFIX_SNAMBET,
  PREFIX_UF9Pro,
  PREFIX_UFABER1,
  PREFIX_UFABETAG,
  PREFIX_UFABETAM,
  PREFIX_UFAFUNVIP,
  PREFIX_UFAGAME,
  PREFIX_UFAKUB555,
  PREFIX_UFAMM,
  PREFIX_UFAONLINE1,
  PREFIX_UFCASINO7,
} from "lib/infomation_vender/document";
import { AppReducerStateProps } from "lib/store";
import Img from "react-cloudimage-responsive";
import { connect } from "react-redux";

interface Props {
  infomation: CheckWithPrefixResponse;
}

function HomeBannerComponent(props: Props) {
  const contentUfa = () => {
    switch (props.infomation.prefix) {
      case PREFIX_UFABER1:
        return (
          <>
            <h3 className="text-center" style={{ fontSize: 30 }}>
              <span>แทงบอลออนไลน์ UFABET</span>
            </h3>
            <h2 className="text-center" style={{ fontSize: 30 }}>
              <span>ยูฟ่าเบท | UFABER1</span>
            </h2>
          </>
        );
      case PREFIX_UFABETAM:
        return (
          <>
            <h3 className="text-center" style={{ fontSize: 30 }}>
              <span>แทงบอลออนไลน์ UFABET</span>
            </h3>
            <h2 className="text-center" style={{ fontSize: 30 }}>
              <span>ยูฟ่าเบท | UFABET.AM</span>
            </h2>
          </>
        );
      case PREFIX_SNAMBET:
        return (
          <>
            <h3 className="text-center" style={{ fontSize: 30 }}>
              <span>แทงบอลออนไลน์ UFABET</span>
            </h3>
            <h2 className="text-center" style={{ fontSize: 30 }}>
              <span>ยูฟ่าเบท | UFABET.SNAM</span>
            </h2>
          </>
        );
      case PREFIX_UFAKUB555:
        return (
          <>
            <h3 className="text-center" style={{ fontSize: 30 }}>
              <span>แทงบอลออนไลน์ UFABET</span>
            </h3>
            <h2 className="text-center" style={{ fontSize: 30 }}>
              <span>ยูฟ่าเบท | UFAKUB555</span>
            </h2>
          </>
        );
      case PREFIX_UFAGAME:
        return (
          <>
            <h3 className="text-center" style={{ fontSize: 30 }}>
              <span>แทงบอลออนไลน์ UFABET</span>
            </h3>
            <h2 className="text-center" style={{ fontSize: 30 }}>
              <span>ยูฟ่าเบท | UFAGAME</span>
            </h2>
          </>
        );
      case PREFIX_UFAONLINE1:
        return (
          <>
            <h3 className="text-center" style={{ fontSize: 30 }}>
              <span>แทงบอลออนไลน์ UFABET</span>
            </h3>
            <h2 className="text-center" style={{ fontSize: 30 }}>
              <span>ยูฟ่าเบท | UFAONLINE1</span>
            </h2>
          </>
        );
      case PREFIX_UFABETAG:
        return (
          <>
            <h3 className="text-center" style={{ fontSize: 22 }}>
              <span>UFABET เว็บตรง ระบบ Auto เติม-ถอน 2 วิ ต้อง UFABET.AG</span>
            </h3>
            <h2 className="text-center" style={{ fontSize: 22 }}>
              <span>มีพนักงานตอบทุกคำถามตลอด 24 ชม.</span>
            </h2>
          </>
        );
      case PREFIX_UFCASINO7:
        return (
          <>
            <h3 className="text-center" style={{ fontSize: 30 }}>
              <span>แทงบอลออนไลน์ UFABET</span>
            </h3>
            <h2 className="text-center" style={{ fontSize: 30 }}>
              <span>ยูฟ่าเบท | UFCASINO7</span>
            </h2>
          </>
        );
      case PREFIX_UF9Pro:
        return (
          <>
            <h3 className="text-center" style={{ fontSize: 30 }}>
              <span>แทงบอลออนไลน์ UF9Pro</span>
            </h3>
            <h2 className="text-center" style={{ fontSize: 30 }}>
              <span>ยูฟ่าเบท | UF9Pro</span>
            </h2>
          </>
        );
      case PREFIX_UFAMM:
        return (
          <>
            <h3 className="text-center" style={{ fontSize: 30 }}>
              <span>แทงบอลออนไลน์ UFAMM</span>
            </h3>
            <h2 className="text-center" style={{ fontSize: 30 }}>
              <span>ยูฟ่าเบท | UFAMM</span>
            </h2>
          </>
        );
      case PREFIX_UFAFUNVIP:
        return (
          <>
            <h3 className="text-center" style={{ fontSize: 30 }}>
              <span>แทงบอลออนไลน์ UFAFUN.VIP</span>
            </h3>
            <h2 className="text-center" style={{ fontSize: 30 }}>
              <span>ยูฟ่าเบท | UFAFUN.VIP</span>
            </h2>
          </>
        );
      default:
        return <></>;
    }
  };

  const BannerPrefix = () => {
    switch (props.infomation.prefix) {
      case PREFIX_UFABER1:
        return (
          <>
            <Img
              className="margin-top-10 shine"
              ratio={3.2}
              src="uf1-banner-baccarat.png"
              alt="ufabet บาคาร่าออนไลน์"
            ></Img>
            <Img
              className="margin-top-10 shine"
              ratio={3.2}
              src="uf1-banner-slots.png"
              alt="ufabet สล็อตออนไลน์"
            ></Img>
          </>
        );
      case PREFIX_UFAKUB555:
        return (
          <>
            <Img
              className="margin-top-10 shine"
              ratio={3.2}
              src="banner-ufakub555-deposit.png"
              alt="ufabet ฝาก - ถอน 2 วินาที"
            ></Img>
          </>
        );
      case PREFIX_UFABETAM:
        return (
          <>
            <Img
              className="margin-top-10 shine"
              ratio={3.2}
              src="ufaam-football3.jpg"
              alt="ufabetam แทงบอล"
            ></Img>
            <Img
              className="margin-top-10 shine"
              ratio={3.2}
              src="ufabetam-banner-baccarat.png"
              alt="ufabet บาคาร่า"
            ></Img>
            <Img
              className="margin-top-10 shine"
              ratio={3.2}
              src="ufabetam-banner-slot.jpg"
              alt="ufabet สล็อต"
            ></Img>
            <Img
              className="margin-top-10 shine"
              ratio={3.2}
              src="ufabetam-banner-deposit.png"
              alt="ufabet ฝาก ถอน"
            ></Img>
          </>
        );
      case PREFIX_SNAMBET:
        return (
          <>
            <Img
              className="margin-top-10 shine"
              ratio={3.2}
              src="ufaber1-banner2.png"
              alt="ufabet ฝาก - ถอน 2 วินาที"
            ></Img>
          </>
        );
      case PREFIX_UFAGAME:
        return (
          <>
            <Img
              className="margin-top-10 shine"
              ratio={3.2}
              src="1248x428-game3-ufagame.jpg"
              alt="ufabet ฝาก - ถอน 2 วินาที"
            ></Img>
          </>
        );
      case PREFIX_UFAONLINE1:
        return (
          <>
            <Img
              className="margin-top-10 shine"
              ratio={3.2}
              src="uf1-01-banner.png"
              alt="ufabet ฝาก - ถอน 2 วินาที"
            ></Img>
          </>
        );
      case PREFIX_UFABETAG:
        return (
          <>
            {" "}
            <Img
              className="margin-top-10 shine"
              ratio={3.2}
              src="ag-webbanner002.png"
              alt="ufabet pg แจ็คพอต"
            ></Img>
            <Img
              className="margin-top-10 shine"
              ratio={3.2}
              src="ag-webbanner004.png"
              alt="ufabet casino"
            ></Img>
            <Img
              className="margin-top-10 shine"
              ratio={3.2}
              src="ag-webbanner001.png"
              alt="ufabet casino"
            ></Img>
          </>
        );
      case PREFIX_UFCASINO7:
        return (
          <>
            {" "}
            <Img
              className="margin-top-10 shine"
              ratio={3.2}
              src="1248x428-2.jpg"
              alt="ufabet ฝาก - ถอน 2 วินาที"
            ></Img>
          </>
        );
      case PREFIX_UF9Pro:
        return (
          <>
            <Img
              className="margin-top-10 shine"
              ratio={3.2}
              src="uf9pro-b1.png"
              alt="ufabet ฝาก - ถอน 2 วินาที"
            ></Img>
          </>
        );
      case PREFIX_UFAFUNVIP:
        return (
          <>
            <Img
              className="margin-top-10 shine"
              ratio={3.2}
              src="ufafun-banner1.jpg"
              alt="ufabetam บาคาร่า แทงบอล"
            ></Img>
            <Img
              className="margin-top-10 shine"
              ratio={3.2}
              src="ufafun-banner2.jpg"
              alt="ufabet แทงบอล"
            ></Img>
            <Img
              className="margin-top-10 shine"
              ratio={3.2}
              src="ufafun-banner3.jpg"
              alt="ufabet สล็อต"
            ></Img>
          </>
        );
      default:
        return <></>;
    }
  };

  return (
    <>
      {contentUfa()}
      {BannerPrefix()}
      {/* <Carousel
    cols={1}
    rows={1}
    gap={10}
    loop
    autoplay={2000}
    showDots
    scrollSnap={true}
    dotColorActive="#ffffff"
    hideArrow={false}
  >
    {content
      .filter((v) => {
        return v.ContentTypeID == 2 || v.ContentTypeID == 1;
      })
      .map((value, i) => {
        return (
          <Carousel.Item key={i}>
            <Link href={value.Link ?? "/"}>
              <a>
                <Img
                  ratio={3 / 1}
                  src={value.Html ?? ""}
                  alt={value.Alt}
                ></Img>
              </a>
            </Link>
          </Carousel.Item>
        );
      })}
    {promotions.map((value, i) => {
      return (
        <Carousel.Item key={i}>
          <Link href={"/all_promotion"}>
            <a>
              <Img
                ratio={3 / 1}
                src={value.Banner ?? ""}
                alt={value.Type ?? ""}
              ></Img>
            </a>
          </Link>
        </Carousel.Item>
      );
    })}
  </Carousel> */}
    </>
  );
}

const mapStateToProps = (state: AppReducerStateProps) => {
  return {
    state: state,
  };
};

export default connect(mapStateToProps)(HomeBannerComponent);
