import { AppReducerStateProps } from "lib/store";
import { calculateTimeLeft, TimeState } from "lib/time";
import check_time, { CheckTimeResponse } from "lib/worker/api/check_time";
import { useEffect, useState } from "react";
import { connect } from "react-redux";

interface Props {
  state: AppReducerStateProps;
}

function CheckinProgress(props: Props) {
  const { isLogin } = props.state.member;

  const [checkTime, setCheckTime] = useState<CheckTimeResponse>();
  const timeAt = checkTime?.TimeAt;
  const [percent, setPercent] = useState<number>(0);
  const [mincheckin, setMincheckin] = useState<number>();

  useEffect(() => {
    if (isLogin) {
      check_time().then((json) => {
        setCheckTime(json);
      });
    }
  }, [isLogin]);

  const [timeLeft, setTimeLeft] = useState<TimeState>(
    calculateTimeLeft(timeAt ?? "")
  );

  useEffect(() => {
    const timer = setTimeout(() => {
      setTimeLeft(calculateTimeLeft(timeAt ?? ""));
    }, 1000);
    return () => clearTimeout(timer);
  });

  useEffect(() => {
    const d = timeLeft.diff ?? 0;
    setMincheckin(timeLeft.minutes);
    setPercent((d / 1780000) * 100);
  }, [timeLeft]);

  return (
    <>
      <div className="progress">
        <div
          className="progress-bar bg-success progress-bar-animated progress-bar-striped"
          role="progressbar"
          style={{ width: `${percent}%` }}
        >
          อีก {mincheckin ?? 0} นาที
        </div>
      </div>
    </>
  );
}

const mapStateToProps = (state: AppReducerStateProps) => {
  return {
    state: state,
  };
};

export default connect(mapStateToProps)(CheckinProgress);
