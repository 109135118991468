import { CheckWithPrefixResponse } from "lib/infomation_vender/document";
import { AppReducerStateProps } from "lib/store";
import { connect } from "react-redux";

interface Props {
  infomation: CheckWithPrefixResponse;
}

function HomeContentFooterSnam(_props: Props) {
  return (
    <>
      <div>
        <h2 style={{ fontSize: 30, marginTop: 20 }}>
          <span>
            ทางเข้าUFABET บริษัทแม่เจ้าใหญ่ ผู้ให้บริการเกมพนันออนไลน์
          </span>
        </h2>
        <p>
          <span>ทางเข้าUFABET</span>{" "}
          เว็บคาสิโนเจ้าใหญ่เจ้าแรกที่โด่งดังมาอย่างยาวนาน
          เป็นที่รู้จักแทบจะทั่วโลกเลยก็ว่าได้
          ด้วยความทันสมัยพร้อมเทคโนโลยีที่เข้ามามีบทบาทกับยุคปัจจุบัน
          และเป็นที่จับตามองกับผู้ที่อยากจะนำไปต่อยอดในรูปแบบ เว็บคาสิโนย่อย ๆ
          เพราะ เว็บไซต์UFABET เป็นเว็บไซต์แรกๆ
          ที่ทำให้เหล่านักพนันทั่วทุกมุมโลกเข้าถึงอย่างง่ายดาย
          โดยที่ไม่จำเป็นต้องเดินทางไปถึง คาสิโนจริงๆ
          เพียงท่านมีโทรศัพท์มือถือหรือคอมพิวเตอร์ ขั้นตอนสมัครง่ายๆ
          ผ่านทางเว็บไซต์
          ท่านก็สามารถเข้าเล่นเกมพนันออนไลน์ค่ายใหญ่ค่ายดังที่มีมากกว่า 500 เกม
          ยูฟ่าเบท ได้รับการยอมรับจากผู้ใช้งานมากมาย ในเรื่องของความปลอดภัย
          ความน่าเชื่อถือรวมไปถึงระบบเครือข่ายที่มีประสิทธิภาพ
          เปิดให้บริการตลอดเวลา สะดวก สบาย มีทีมงานผู้เชี่ยวชาญคอยตอบปัญหา 24
          ชั่วโมง ระบบฝาก-ถอน ที่รวดเร็ว ถอนได้ไม่มีขั้นต่ำ
        </p>
        <h2 style={{ fontSize: 30, marginTop: 10 }}>
          <span>UFABETพนันออนไลน์ แหล่งรวมเกมคาสิโนออนไลน์ ค่ายดัง</span>
        </h2>
        <p>
          <span>คาสิโนออนไลน์</span> เว็บใหญ่เว็บดังขึ้นชื่อเรื่อง การเงินมั่นคง
          ซื่อตรงต่อลูกค้า เป็นเว็บไหนไปไม่ได้นอกจาก
          <span> UFABETเว็บตรงไม่ผ่านเอเย่นต์</span>
          เป็นเว็บไซต์ที่ได้การตอบรับจากเหล่านักพนันทั้งประเทศไทยและเอเชีย
          สดส่งตรงจากต่างประเทศ มีความเสถียร ระบบการเดิมพันที่มีประสิทธิภาพ
          การพัฒนาอัพเดทระบบ เกมพนันออนไลน์ ที่มีความลื่นไหลไม่มีสะดุด
          รวมค่ายเกมดังระดับชั้นนำ ไม่ว่าจะเป็น เกมไพ่บาคาร่า, สล็อตออนไลน์,
          เกมยิงปลา, ไฮโล,{" "}
          <span>
            SA GAMING, AE SEXY, REDTIGER, EBET, PG SLOT, KINGMAKER
          </span>{" "}
          ภายในเว็บไซต์ ยูฟ่าเบทมือถือ ยังมีเกมพนันกีฬา แทงบอล แทงบาส มวยสเต็ป
          ฯลฯ ให้นักพนันมือใหม่และมืออาชีพได้เลือกเดิมพันตามต้องการ เล่นง่าย
          จ่ายเต็มทุกยอดการเล่น ระบบฝาก-ถอนออโต้ รวดเร็วภายใน 1 นาที
          มีทีมงานคุณภาพคอยให้บริการตลอด 24 ชั่วโมง
        </p>
      </div>
    </>
  );
}

const mapStateToProps = (state: AppReducerStateProps) => {
  return {
    state: state,
  };
};

export default connect(mapStateToProps)(HomeContentFooterSnam);
