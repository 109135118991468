import Img from "react-cloudimage-responsive";

export default function HomeContentUfabet() {
  const slides = [
    {
      image: "sa-gaming-ufamobile-casino.png",
      alt: "SA GAMiNG คาสิโนออนไลน์และบาคาร่า",
    },
    {
      image: "big-ufa-casino.png",
      alt: "big casino คาสิโนบาคาร่าชั้นนำ",
    },
    {
      image: "pg-ufamobile-casino.png",
      alt: "PG แหล่งรวมเกมสล็อต มากมายหลากหลายเกม",
    },
    {
      image: "ae-sexy-gaming-ufa-casino.png",
      alt: "AE SEXY GAMING เกมและบาคาร่า กับ สาวๆสุดเซ็กซี่ในชุดบิกินี่",
    },
    {
      image: "ebet-ufamobile-casino.png",
      alt: "EBET แหล่งรวมเกมพนันออนไลน์ชั้นนำ",
    },
    {
      image: "gamehall-ufamobile-casino.png",
      alt: "GAMEHALL777 รวมค่ายเกมดังต่างๆไว้ที่นี้ที่เดียว",
    },
    {
      image: "spadegaming-ufamobile-casino.png",
      alt: "SPADE GAMING รวมเกมสล็อต และเกมดังอย่าง fishing god ไว้ที่นี้",
    },
    {
      image: "joker-ufamobile-casino.png",
      alt: "JOKER GAMING รวมเกมสล็อตมากมาย ที่มีคุณภาพ",
    },
    {
      image: "kingmaker-ufa-casino.png",
      alt: "KINGMAKER SLOT รวมเกมสล็อตมากมาย UFABET",
    },
    {
      image: "redtiger-ufa-casino.png",
      alt: "REDTIGER เกมคุณภาพ แจกฟรีสปิน",
    },
    {
      image: "fachai-ufa-casino.png",
      alt: "Fachai Slot แตกรางวัลใหญ่ได้ง่ายๆ",
    },
    {
      image: "ili-ufa-casino.png",
      alt: "JILI GAMING ค่ายเกมสล็อต มีเกมคุณภาพให้คุณได้ลอง",
    },
  ];

  return (
    <div>
      <div className="row">
        {slides.map((value, i) => {
          return (
            <div key={i} className="col-6 col-md-3 margin-top-20">
              <div className="border-ufa card-ufa-category button-glowing">
                <Img ratio={1.8} src={value.image} alt={value.alt}></Img>
              </div>
            </div>
          );
        })}
      </div>
      <p className="text-danger margin-top-10">
        ไม่อนุญาตให้บุคคลที่มีอายุตํ่ากว่า 18 ปี เล่นการพนัน และ
        ไม่สามารถเปิดบัญชีเพื่อเข้าเล่นการพนันได้
        ยกเว้นการพนันบางประเภทตามที่กฎหมายกำหนด
      </p>
    </div>
  );
}
