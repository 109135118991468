import { CheckWithPrefixResponse } from "lib/infomation_vender/document";
import { AppReducerStateProps } from "lib/store";
import { connect } from "react-redux";

interface Props {
  infomation: CheckWithPrefixResponse;
}

function HomeContentMainSnam(_props: Props) {
  return (
    <>
      <div>
        <h1 style={{ fontSize: 30, marginTop: 10 }}>
          <span>UFABETเว็บตรง คาสิโนออนไลน์ ถอนไว ไม่มีขั้นต่ำ</span>
        </h1>
        <p>
          <span>UFABET</span> เว็บพนันออนไลน์ที่มีคุณภาพได้มาตรฐานตามหลักสากล
          ส่งตรงจากต่างประเทศ <span>ยูฟ่าเบท</span> เป็นแหล่งรวมเกม
          คาสิโนออนไลน์ ที่ทั่วโลกให้การยอมรับในเรื่องของ การเงินมั่นคง
          ความปลอดภัย รองรับลูกค้าได้ทุกประเภทและมีผู้เล่นมากถึง 50,000 คนต่อวัน
          ด้วยการเล่นผ่านระบบออนไลน์ทุกแพลตฟอร์มไม่ว่าจะเป็น โทรศัพท์มือถือ
          คอมพิวเตอร์หรือแท็บเล็ต
          เพียงท่านเชื่อมต่ออินเตอร์เน็ตก็สามารถเล่นได้ทุกที่ทุกเวลาตามต้องการ
          <span>UFABETเว็บไซต์</span> การันตีรายได้ผลตอบแทน คืนกำไร
          ให้กับสมาชิกทุกท่านอย่างแน่นอน รวมเกมพนันชั้นนำทั่วโลก เล่นง่าย
          จ่ายจริง ด้วยระบบฝาก-ถอนอัตโนมัติ รวดเร็วภายใน 1 นาที
          ถอนได้ไม่มีขั้นต่ำทุกยอดการเล่น ทำให้การเดิมพันราบรื่นไม่ติดขัด
          เพราะเว็บไซต์เราเป็นเว็บแท้ ลิขสิทธิ์แท้ เว็บตรงไม่ผ่านเอเย่นต์
          มอบให้กับผู้ลงทุนทุกๆท่าน รับรองไม่ผิดหวัง
        </p>
      </div>
    </>
  );
}

const mapStateToProps = (state: AppReducerStateProps) => {
  return {
    state: state,
  };
};

export default connect(mapStateToProps)(HomeContentMainSnam);
