import client from "../client";

interface Request {
  prefix: string;
}

export interface GetBankTypeAllResponse {
  ID?: number;
  Type?: string;
  Image?: null | string;
  Color?: null | string;
}

export default function API(): Promise<GetBankTypeAllResponse[]> {
  return client
    .get(`genaral/get_bank_type_all`)
    .then((response) => response.data);
}

export function apigetbankonlywebsite(
  request: Request
): Promise<GetBankTypeAllResponse[]> {
  return client
    .get(`genaral/get_bank_type_all_with_website`, { params: request })
    .then((response) => response.data);
}
