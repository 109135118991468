import { AppReducerStateProps } from "lib/store";
import { connect } from "react-redux";
import FooterSnam from "../view/footer_snam";
import React from "react";
import HomeMenuSectionComponent from "components/home/home_menu_selection";
import { GetVenderDomainInfomationResponse } from "lib/worker/api/get_vender_domain_infomation";
import { GetAllContentByPrefixResponse } from "lib/worker/api/get_all_content_by_prefix";
import { CheckWithPrefixResponse } from "lib/infomation_vender/document";
import { GetPromotionTypesResponse } from "lib/worker/api/get_promotion_types";
import HomeComment from "components/home/home_comment";
import HomeJackpot from "./home_jackpot";
import HomeStatusMemberCompoment from "./home_status_member";
import HomeUgameSpeed from "./home_ugame_speed";
import HomeInviteFriend from "./home_invite_friend";
import HomeContentAbout from "./home_content_about";
import HomeContentUfabet from "./home_content_ufabet";
import HomeBannerSnam from "./home_banner_snam";
import HomeContentSnam from "./home_content_snam";
import HomeContentMainSnam from "./home_content_main_snam";
import HomeContentFooterSnam from "./home_content_footer_snam";
import HomeLogoSnam from "./home_logo_snam";

interface HomeProps {
  state: AppReducerStateProps;
  venderInfomation: GetVenderDomainInfomationResponse;
  content: GetAllContentByPrefixResponse[];
  infomation: CheckWithPrefixResponse;
  promotions: GetPromotionTypesResponse[];
}

function HomeUfabetSnam(_props: HomeProps) {
  const { isLogin } = _props.state.member;

  return (
    <>
      <div className="container" style={{ paddingTop: 5 }}>
        <HomeLogoSnam></HomeLogoSnam>
      </div>
      <div className="container">
        {!isLogin && (
          <div className="margin-top-10 margin-bottom-10">
            <HomeBannerSnam infomation={_props.infomation}></HomeBannerSnam>
          </div>
        )}
      </div>
      <div className="container">
        {isLogin && (
          <>
            <HomeStatusMemberCompoment
              infomation={_props.infomation}
            ></HomeStatusMemberCompoment>
          </>
        )}
      </div>
      <div className="container">
        <HomeMenuSectionComponent
          isLoading={false}
          infomation={_props.infomation}
        ></HomeMenuSectionComponent>
      </div>
      <div className="container">
        <HomeJackpot information={_props.infomation}></HomeJackpot>
      </div>
      <div className="container">
        <HomeContentMainSnam
          infomation={_props.infomation}
        ></HomeContentMainSnam>
      </div>
      <div className="container margin-top-10">
        <HomeUgameSpeed></HomeUgameSpeed>
      </div>
      {!isLogin && (
        <div className="margin-top-10 margin-bottom-10 container">
          <HomeContentSnam infomation={_props.infomation}></HomeContentSnam>
        </div>
      )}
      <div className="container margin-top-20">
        <HomeInviteFriend infomation={_props.infomation}></HomeInviteFriend>
      </div>
      <div className="container">
        <HomeComment infomation={_props.infomation}></HomeComment>
      </div>
      <div className="container">
        <HomeContentFooterSnam
          infomation={_props.infomation}
        ></HomeContentFooterSnam>
      </div>

      <div className="container">
        <HomeContentUfabet></HomeContentUfabet>
      </div>
      <div className="container">
        <HomeContentAbout infomation={_props.infomation}></HomeContentAbout>
      </div>
      <div className="container">
        <FooterSnam></FooterSnam>
      </div>
    </>
  );
}

const mapStateToProps = (state: AppReducerStateProps) => {
  return {
    state: state,
  };
};

export default connect(mapStateToProps)(HomeUfabetSnam);
