import { CheckWithPrefixResponse } from "lib/infomation_vender/document";
import { AppReducerStateProps } from "lib/store";
import { connect } from "react-redux";

interface Props {
  infomation: CheckWithPrefixResponse;
}

function HomeContentSnam(_props: Props) {
  return (
    <>
      <div>
        <h2 style={{ fontSize: 30, paddingTop: 20 }}>
          <span>สมัครเว็บแทงบอลที่ดีที่สุด แทงบอลออนไลน์ บน UFABETมือถือ</span>
        </h2>
        <p>
          แทงบอลออนไลน์ ถือเป็นอีกหนึ่งการลงทุนที่นิยมไปทั่วทุกมุมโลก
          UFABETเว็บตรง จึงพัฒนาให้มีความทันสมัยมากยิ่งขึ้น เพื่อเข้าถึงลูกค้า
          โดยเปิดบริการผ่านระบบออนไลน์ให้ทันยุคทันสมัยในปัจจุบัน
          รองรับการเดิมพันผ่านระบบ IOS และ Android
          โทรศัพท์มือถือทุกรุ่นทุกยี่ห้อ เพียงท่านเชื่อมอินเตอร์เน็ตก็สามารถ
          แทงบอลสด บอลสเต็ป บอลเดี่ยว บอลเต็ง แทงบอลสูงต่ำ บอลชุด ผลรวมประตู
          และอีกหลากหลายรูปแบบ
          ทางเว็บเรามีให้ท่านเลือกเดิมพันแบบจุใจกันเลยทีเดียว ยูฟ่าเบท
          เว็บตรงไม่ผ่านเอเย่นต์ เว็บแทงบอลน้ำดี ราคาบอลดีที่สุด
          เดิมพันขั้นต่ำเพียง 10 บาทเท่านั้น คืนค่าคอมมิชชั่นสูงถึง 0.5%
          เว็บพนันUFABET จึงเป็นอันดับ 1 ของเว็บพนันออนไลน์มานานกว่า 10 ปี
          การันตีคุณภาพ การเงิน และความปลอดภัย เปิดให้บริการตลอด 24 ชั่วโมง
          แทงบอลต้องUFABETเท่านั้น
        </p>
        <h3 style={{ fontSize: 30, paddingTop: 20 }}>
          <span>บาคาร่าออนไลน์ เว็บไซต์UFABET เว็บเดิมพันอันดับ 1 ทั่วโลก</span>
        </h3>
        <p>
          บาคาร่าเว็บตรง เว็บยูฟ่าเบทออนไลน์ เปิดให้บริการกับสมาชิกทุกท่าน
          ทั้งผู้เล่นใหม่และเหล่าเซียนพนัน เริ่มเล่นกับเว็บไซต์ของเรา
          สมัครฟรีไม่เสียค่าใช้จ่าย เป็นเกมไพ่ที่มาแรงที่สุดในปัจจุบัน
          มีผู้เล่นต่อวันมากถึง 50,000 คน สามารถสร้างรายได้ในแต่ละวันจาก
          เว็บบาคาร่าUFABET การันตีรายได้เป็นกอบเป็นกำ การเงินที่มั่นคง
          หากท่านได้ลอง จะติดใจไม่นอกใจแน่นอน รูปแบบและวิธีการเล่นน่าสนใจ
          เล่นง่าย ไม่ซับซ้อน วางเงินเดิมพันเพียงไม่กี่วินาที
          ได้กำไรอย่างเหลือเชื่อ อย่าเชื่อจนกว่าจะได้สัมผัส
          เว็บของเราเป็นเว็บตรง ลิขสิทธิ์แท้
          มีใบเซอร์รับรองได้มาตรฐานถูกต้องตามหลักสากล บาคาร่าได้เงินจริง
          ตอบโจทย์สำหรับนักลงทุนที่กำลังมองหา เว็บตรง มั่นคง ปลอดภัย
          ต้องUFABETเท่านั้น เว็บพนันออนไลน์ที่ดีที่สุด หากท่านได้ลองแล้วจะติดใจ
          ได้กำไรกลับไปอย่างแน่นอน{" "}
        </p>
      </div>
    </>
  );
}

const mapStateToProps = (state: AppReducerStateProps) => {
  return {
    state: state,
  };
};

export default connect(mapStateToProps)(HomeContentSnam);
