import {
  CheckWithPrefixResponse,
  PREFIX_SNAMBET,
  PREFIX_UF9Pro,
  PREFIX_UFABER1,
  PREFIX_UFABETAG,
  PREFIX_UFABETAM,
  PREFIX_UFAFUNVIP,
  PREFIX_UFAGAME,
  PREFIX_UFAKUB555,
  PREFIX_UFAMM,
  PREFIX_UFAONLINE1,
  PREFIX_UFCASINO7,
} from "lib/infomation_vender/document";
import { AppReducerStateProps } from "lib/store";
import Link from "next/link";
import Img from "react-cloudimage-responsive";
import { connect } from "react-redux";

interface Props {
  infomation: CheckWithPrefixResponse;
}

function HomeContentArticle(props: Props) {
  const contentPrefix = () => {
    switch (props.infomation.prefix) {
      case PREFIX_UFABER1:
        return (
          <>
            <span>UFABER1</span>
          </>
        );
      case PREFIX_UFABETAM:
        return (
          <>
            <span>UFABETAM</span>
          </>
        );
      case PREFIX_SNAMBET:
        return (
          <>
            <span>UFABETSNAM</span>
          </>
        );
      case PREFIX_UFAKUB555:
        return (
          <>
            <span>UFAKUB555</span>
          </>
        );
      case PREFIX_UFAGAME:
        return (
          <>
            <span>UFAGAME</span>
          </>
        );
      case PREFIX_UFAONLINE1:
        return (
          <>
            <span>UFAONLINE1</span>
          </>
        );
      case PREFIX_UFABETAG:
        return (
          <>
            <span>UFABET.AG</span>
          </>
        );
      case PREFIX_UFCASINO7:
        return (
          <>
            <span>UFCASINO7</span>
          </>
        );
      case PREFIX_UF9Pro:
        return (
          <>
            <span>UF9Pro</span>
          </>
        );
      case PREFIX_UFAMM:
        return <>UFAMM</>;
      case PREFIX_UFAFUNVIP:
        return <>UFAFUN.VIP</>;
      default:
        return <></>;
    }
  };

  const contentUfa = () => {
    switch (props.infomation.prefix) {
      case PREFIX_UFABETAM:
        return (
          <div>
            <h1 style={{ fontSize: 30, marginTop: 20 }}>
              <span>
                UFABET.AM แทงบอลออนไลน์ เว็บตรงยูฟ่าเบทอันดับหนึ่งในไทย
              </span>
            </h1>
            <p>
              <span>UFABET.AM</span> หนึ่งในผู้ให้บริการพนันออนไลน์ที่ดีที่สุด
              เป็นผู้ที่ให้บริการผ่านทางเว็บตรง ไม่ผ่านเอเย่นต์
              ให้บริการด้วยระบบความปลอดภัยที่สูง และเชื่อถือได้
              ซึ่งปัจจุบันเรามีทีมงานคุณภาพระดับมืออาชีพที่ให้บริการดูแลนักเดิมพันเป็นอย่างดี
              และเว็บแทงบอลออนไลน์ของเรา การันตีความมั่นคงด้านทางการเงิน
              และบริการต่างๆ ได้อย่างมีคุณภาพ
              ทำให้สามารถตอบโจทย์สำหรับคนรุ่นใหม่ทุกคนได้เป็นอย่างดี
            </p>
            <p>
              และมีการให้บริการในรูปแบบใหม่ที่ดีกว่าเดิม คาสิโน บาคาร่า
              สล็อตออนไลน์ ซึ่งทางเราได้เปิดให้บริการในรูปแบบของคาสิโนสด{" "}
              <span>Live casino</span>{" "}
              คุณจะได้สัมผัสบรรยากาศเสมือนอยู่ในสนามการเดิมพันจริง
              และคุณสามารถเข้าใช้งานผ่านอุปกรณ์ที่เชื่อมต่อกับอินเทอร์เน็ต เช่น
              คอมพิวเตอร์ โน๊ตบุ๊ค มือถือ และอื่นๆ อีกเพียบ สามารถเล่นได้ทุกที่
              ทุกเวลา ไม่ต้องเสียเวลาเดินทางไปด้วยตัวเองอีกต่อไป
              และทางเว็บพนันออนไลน์ของเราก็เปิดให้บริการตลอด 24 ชั่วโมง
            </p>
            <p>
              การเข้ามา แทงบอล UFABET.AM
              ของเราถือว่าเป็นอีกหนึ่งช่องทางที่ดีที่สุดสำหรับคนรุ่นใหม่ที่ไม่ต้องเสียเวลาเดินทางไปบ่อน
              และยังเปิดโอกาสให้คนที่ไม่ค่อยมีเวลา
              แต่อยากเล่นก็สามารถเข้ามาใช้งานกับทางเราได้
              ซึ่งเป็นผู้ให้บริการที่เพียบพร้อมไปด้วยการบริการดังนั้นวันนี้เราจะพาคุณไปเจาะลึกทำความรู้จักกับเว็บพนันออนไลน์ที่ดีที่สุดจะเป็นอย่างไรบ้างไปติดตามดูกันได้เลย
            </p>
            <h2 style={{ fontSize: 28, marginTop: 10 }}>
              <span>5 ข้อดีของการแทงบอลกับ UFABET.AM</span>
            </h2>
            <p>
              1. เล่นตรงไม่ผ่านเอเย่นต์ เว็บนี้เป็นเว็บที่ส่งตรงจากยูฟ่าเบท
              พนันบอล ไม่ได้เป็นเอเย่นต์เหมือนเว็ปทั่วไปบางเว็บ
              ทำให้มั่นใจได้ว่าเว็บจะไม่มีการโกงหรือปิดหนี้อย่างแน่นอน
            </p>
            <p>
              2. แทงขั้นต่ำ 20 บาท
              สำหรับผู้ที่เริ่มเล่นแทงบอลการลงแทงบอลอาจจะมีความมั่นใจน้อย
              จึงไม่อยากลงทุนในราคาสูงนัก
              ซึ่งเว็บเข้าใจดีจึงมีราคาขั้นต่ำในการแทงบอลสเต็ปเพียงแค่คู่ละ 10
              บาท แต่จะต้องทำการแทงบอลสเต็ปจำนวน 2 คู่ขึ้นไปเท่านั้น
              ก็คือมีมูลค่ารวมในการแทงเพียงแค่ 20 บาทเท่านั้นเอง
              ซึ่งถือว่าเป็นราคาที่ต่ำมากสำหรับการแทงบอลแต่ละครั้ง
            </p>
            <p>
              3. จำนวนคู่บอลเยอะมาก จำนวนคู่บอลที่ทาง UFABET.AM
              เปิดให้แทงมีจำนวนมาก เพราะเรารวมบอลทุกลีกทั้งลีกเล็ก
              ลีกกลางและลีกใหญ่
              ทุกคู่ที่ทำการแข่งมาเปิดให้นักพนันทำการแทงกันอยู่ทุกวัน
              ทำให้นักพนันมีคู่ที่ทำการแข่งตัวเลือกแทงเป็นจำนวนมาก
              และสามารถเลือกได้ตามใจของท่านเอง
              ทางเว็บไม่มีการบังคับคู่ที่ต้องการแทงทั้งแบบเต็งและแบบสเต็ป
            </p>
            <p>
              4. ถ่ายทอดสดผ่านเว็บ
              สำหรับนักพนันที่ต้องการชมการแข่งขันแบบถ่ายทอดสดจากสนาม
              ทางเว็บก็มีบริการถ่ายทอดสดตอลดการแข่งให้นักพนันได้รับชมตามความชอบ
              ซึ่งสามารถเข้าชมได้ตลอดเวลา
              สัญญาณที่ใช้ในการถ่ายทอดสดเป็นสัญญาณผ่านดาวเทียมที่มีความเร็วและความคมชัดสูงที่สุด
              ทำให้ผู้ชมสามารถเห็นบรรยากาศทุกอย่างในสนามสมจริงทุกประการ
            </p>
            <p>
              5. วิเคราะห์บอลสุดแม่น
              ก่อนที่จะทำการแข่งขันเรามีทีมทำการวิเคราะห์บอล
              ให้กับแฟนบอลได้ทราบทุกอย่างที่เกิดขึ้นก่อนการแข่งขัน
              ทั้งสถิติการแข่งขัน
              สภาพความพร้อมของนักกีฬาและแนวโน้มผลการแข่งให้นักพนันได้ทราบ
              ซึ่งวิเคราะห์บอลของเราจัดทำด้วยทีมที่มีความเชี่ยวชาญและประสบการณ์มาหลายสิบปี
              รับรองว่าผลการวิเคราะห์ที่ได้มีความแม่นยำสูง
            </p>
            <h3 style={{ fontSize: 26, marginTop: 20 }}>
              <span>ยูฟ่าเบท ความน่าสนใจ UFABET มีอะไรบ้าง</span>
            </h3>
            <p>
              ความน่าสนใจของการเข้ามาใช้บริการ ยูฟ่าเบท
              ปัจจุบันมีการพัฒนาอย่างต่อเนื่องทำให้มีระบบความน่าสนใจต่างๆ
              ที่สามารถตอบโจทย์ และความต้องการของนักเดิมพันได้
              รับรองว่าเมื่อเข้ามาใช้งานเว็บของเราจะได้รับความน่าสนใจ
              ดังต่อไปนี้
            </p>
            <p style={{ fontSize: 24 }}>
              <span>บริการความปลอดภัย และความมั่นคง</span>
            </p>
            <p>
              คาสิโนออนไลน์ รวม บาคาร่า สล็อต
              ที่ให้บริการด้วยระบบความปลอดภัยที่สูง มีการดูแล และควบคุมทุกส่วน
              พร้อมทีมงามคุณภาพที่จะดูแลระบบเป็นอย่างดี
              เมื่อเข้ามาใช้งานทำให้นักเดิมพันนั้นอุ่นใจ และไม่ต้องกังวลใดๆ
              ทั้งสิ้นเพราะเราให้บริการด้วยความปลอดภัย ถูกกฎหมาย
              และไม่ผ่านเอเย่นต์
              นอกจากนี้ยังมีระบบความมั่นคงทางการเงินไม่มีรั่วไหล
              ไม่มีผิดพลาดอย่างแน่นอน
            </p>
            <p style={{ fontSize: 24 }}>
              <span>UFABET.AM บริการดีใช้งานง่าย</span>
            </p>
            <p>
              เว็บของเรา
              เป็นเว็บที่เข้าใจนักเดิมพันมากที่สุดจึงทำให้ได้มีการพัฒนาช่องทางการเข้าใช้งานให้มีความสะดวกสบายมากยิ่งขึ้น
              คุณสามารถเข้าใช้งานโดยตรงทำการค้นหาผ่านทางบราวเซอร์ google
              เพียงเท่านี้ก็เข้าใช้งานได้ทันที
              และทางเข้าใช้งานก็มีระบบคอยตรวจสอบ
              และดูแลเป็นอย่างดีจึงทำให้ทุกการเข้าใช้งานนั้นปลอดภัยอย่างแน่นอน
            </p>
            <p style={{ fontSize: 24 }}>
              <span>UFABET.AM ให้ค่าตอบแทนในการเดิมพันสูง</span>
            </p>
            <p>
              อัตราการจ่ายเงินรางวัลของทางเรามีค่าตอบแทนที่สูงมากในแต่ละเกมส์
              ซึ่งอัตราการจ่ายก็จะขึ้นอยู่ที่เงิน และกฎของเกมส์นั้นๆ
              เพราะบางเกมส์ลงทุนเดิมพันไปเพียงหลักหน่วยแต่มีโอกาสคว้าเงินรางวัลสูงถึงหลักหมื่นบาท
              และทางเราเหมาะกับการเข้ามาทำเงิน หรือสร้างรายได้ดีๆ อย่างมาก
            </p>
          </div>
        );

      default:
        return <></>;
    }
  };

  const DownloadBanner = () => {
    switch (props.infomation.prefix) {
      case PREFIX_UFABER1:
        return (
          <>
            <Link href="download_app">
              <a>
                <Img
                  className="margin-top-10 shine"
                  ratio={3.2}
                  src="ufaber1-banner3.png"
                  alt="โหลดแอป ufaber1"
                ></Img>
              </a>
            </Link>
          </>
        );
      case PREFIX_UFABETAM:
        return (
          <>
            <Link href="download_app">
              <a>
                <Img
                  className="margin-top-10 shine"
                  ratio={3.2}
                  src="ufabetam-download.png"
                  alt="โหลดแอป ufabetam"
                ></Img>
              </a>
            </Link>
          </>
        );
      case PREFIX_SNAMBET:
        return (
          <>
            <Link href="download_app">
              <a>
                <Img
                  className="margin-top-10 shine"
                  ratio={3.2}
                  src="ufaber1-banner3.png"
                  alt="โหลดแอป ufabet"
                ></Img>
              </a>
            </Link>
          </>
        );
      case PREFIX_UFAKUB555:
        return (
          <>
            <Link href="download_app">
              <a>
                <Img
                  className="margin-top-10 shine"
                  ratio={3.2}
                  src="banner-ufakub555-download.png"
                  alt="โหลดแอป ufakub555"
                ></Img>
              </a>
            </Link>
          </>
        );

      case PREFIX_UFAGAME:
        return (
          <>
            <Link href="download_app">
              <a>
                <Img
                  className="margin-top-10 shine"
                  ratio={3.2}
                  src="1248x428-game2-ufagame.jpg"
                  alt="โหลดแอป ufagame"
                ></Img>
              </a>
            </Link>
          </>
        );
      case PREFIX_UFAONLINE1:
        return (
          <>
            <Link href="download_app">
              <a>
                <Img
                  className="margin-top-10 shine"
                  ratio={3.2}
                  src="1248x428-1-ufaonline1.jpg"
                  alt="โหลดแอป ufakub555"
                ></Img>
              </a>
            </Link>
          </>
        );
      case PREFIX_UFABETAG:
        return (
          <>
            <Link href="download_app">
              <a>
                <Img
                  className="margin-top-10 shine"
                  ratio={3.2}
                  src="ag-webbanner006.png"
                  alt="โหลดแอป ufabet ag"
                ></Img>
              </a>
            </Link>
          </>
        );
      case PREFIX_UFCASINO7:
        return (
          <>
            <Link href="download_app">
              <a>
                <Img
                  className="margin-top-10 shine"
                  ratio={3.2}
                  src="1248x428-3.jpg"
                  alt="โหลดแอป ufcasino7"
                ></Img>
              </a>
            </Link>
          </>
        );
      case PREFIX_UF9Pro:
        return (
          <>
            <Link href="download_app">
              <a>
                <Img
                  className="margin-top-10 shine"
                  ratio={3.2}
                  src="uf9pro-b2.png"
                  alt="โหลดแอป uf9pro"
                ></Img>
              </a>
            </Link>
          </>
        );
      case PREFIX_UFAMM:
        return <></>;
      case PREFIX_UFAFUNVIP:
        return <></>;
      default:
        return <></>;
    }
  };

  const UFABER1 = () => {
    switch (props.infomation.prefix) {
      case PREFIX_UFABER1:
        return (
          <>
            <Img
              className="margin-top-10 shine"
              ratio={3.2}
              src="uf1-banner-sports.png"
              alt="ufabet แทงบอลออนไลน์"
            ></Img>
            <Img
              className="margin-top-10 shine"
              ratio={3.2}
              src="ufaber1-banner2.png"
              alt="ufabet ฝาก - ถอน 2 วินาที"
            ></Img>
          </>
        );

      default:
        return <></>;
    }
  };

  return (
    <>
      <div>
        {contentUfa()}
        <p className="text-center" style={{ paddingTop: 10, paddingBottom: 0 }}>
          ตัวแทนที่ได้รับสิขสิทธิ์อย่างเป็นทางการจาก <span>UFABET</span>{" "}
          ยูฟ่าเบท เว็บแทงบอลออนไลน์ ที่ได้มาตรฐานระดับโลกที่ดีที่สุดในประเทศไทย
          ท่านสามารถเดิมพันกีฬาออนไลน์ แทงบอลออนไลน์ บอลสเต็ป บอลชุด บาคาร่า
          คาสิโนออนไลน์ หวย มวย ได้ทุกชนิดโดยเปิดราคามากกว่าเจ้าอื่น
          สะดวกสบายยิ่งขึ้นสามารถวางเดิมพันได้ทุกที่ทุกเวลารองรับมือถือทุกรุ่นทุกระบบ
          และที่สำคัญเป็นภาษาไทยทั้งระบบเว็บไซต์ยูฟ่าเบท <span>UFABET</span>
          มีความปลอดภัยมาตรฐานระดับโลก การป้องกันข้อมูลมากกว่า 10 ชั้น
          ทำให้ท่านลูกค้าไม่ต้องกังวลกับความปลอดภัยด้านข้อมูลส่วนตัว
          ที่รองรับการพนันบอลออนไลน์ ผ่านอินเตอร์เน็ต
          และคาสิโนออนไลน์ที่มีให้เลือกมากกว่า 1000 เกมส์
          พร้อมทั้งยังมีคาสิโนที่ถ่ายทอดสดตลอด 24 ชั่วโมง พร้อมโปรโมชั่นมากมาย
          <span>{contentPrefix()}</span> มีความยินดีให้บริการทุกท่านตลอด 24
          ชั่วโมง กับทีมงานมืออาชีพพร้อมแก้ไขปัญหาและรับใช้ทุกท่านในการฝากถอน
        </p>
      </div>
      {UFABER1()}
      <h2 className="text-center" style={{ fontSize: 30, paddingTop: 20 }}>
        <span>สมัคร {contentPrefix()} เว็บดีมั่นคงซื่อตรงโอนไว</span>
      </h2>
      <p className="text-center">
        เราเชื่อว่าถ้าคุณพูดถึงการเล่นเกมบนอินเทอร์เน็ตสมัยนี้ก็มีไว้หลากหลายให้รวบรวมเอาเกมสนุกๆมาไว้ให้คุณเล่นแต่จะมีเว็บไซต์ไหนไหมที่ไม่ใช่เพียงแต่รวบรวมเกมมาให้คุณเล่นเท่านั้นแต่ยังจะเป็นเกมที่มอบความคุ้มค่าให้กับคุณไม่ให้คุณต้องเสียเวลาเปล่าได้เล่นเกมสนุกสนานเพลิดเพลินแล้วก็ยังสามารถลุ้นผลตอบแทนได้แบบจุใจจะเป็นเว็บไหนไปไม่ได้จะต้องเป็น
        <span>{contentPrefix()}</span> เท่านั้น
        คนสมัยนี้ใช้อินเทอร์เน็ตการคล่องแคล่วสามารถที่จะ Search
        หาเว็บไซต์ที่รวบรวมเกมอะไรให้เล่นได้ง่ายๆอยู่แล้วแต่คุณจะรู้ได้ยังไงว่าเว็บไหนเป็นเว็บที่ดีที่สุดคุณอย่ามองแต่เพียงว่าเว็บนี้มีเกมให้คุณเลือกเล่นเยอะแต่คุณต้องดูด้วยว่าเว็บไซต์นั้นมีเกมที่มีคุณภาพสามารถเล่นได้อย่างมีสาระและก็คุ้มค่าเหมาะกับเวลาที่คุณเสียไปมากน้อยแค่ไหน
        คุณถ้าคุณเลือกเล่นเกมกับ
        <span>{contentPrefix()}</span>
        คุณไม่ต้องหาแต่ความสนุกสนานเพราะเรายังมอบความคุ้มค่าให้กับคุณเพราะเรามีเกมที่คุณสามารถรอลุ้นผลตอบแทนได้แบบง่ายๆผ่านทางโทรศัพท์มือถือคุณสามารถที่จะเข้ามาเล่นเกมของเราเลือกลงทุนกับเกมที่คุณชื่นชอบแล้วก็ลุ้นผลตอบแทนกับมันได้ทำให้การเล่นเกมของคุณนั้นไม่เสียเวลาเปล่าแต่ยังมีผลกำไรติดไม้ติดมือกลับไป
      </p>
      {DownloadBanner()}
      <h3 className="text-center" style={{ fontSize: 28, paddingTop: 10 }}>
        <span>ดาวน์โหลด APP {contentPrefix()} เล่นผ่านมือถือสะดวกสบาย</span>
      </h3>
      <p className="text-center">
        เล่นง่ายๆผ่านมือถือ สะดวกสบายตลอด24ชั่วโมง ในเว็บ
        <span>{contentPrefix()}</span>
        คุณสามารถเลือกเล่นเกมสนุกๆผ่านทางโทรศัพท์มือถือของคุณตลอด 24
        ชั่วโมงไม่ว่าคุณจะชื่นชอบเกมแบบไหนลักษณะใดเราก็เชื่อว่าเราได้รวบรวมไว้ให้คุณพร้อมให้บริการบนเว็บไซต์ของเราแล้วซึ่งคุณสามารถเลือกเล่นได้ทุกที่ทุกเวลาผ่านทางโทรศัพท์มือถือและที่สำคัญเว็บไซต์ของเรายังรองรับอุปกรณ์ถ้าคุณไม่อยากจะเล่นบนโทรศัพท์มือถือคุณก็สามารถที่จะไปเลือกเล่นบนคอมพิวเตอร์หรือว่าแท็บเล็ตก็ได้แต่การที่คุณเข้าใช้งานผ่านทางโทรศัพท์มือถือก็จะมอบความสะดวกสบายในอีกรูปแบบหนึ่งให้กับคุณ
        ซึ่งปัจจุบันนี้ทุกๆคนมีโทรศัพท์มือถือพกเป็นของตัวเองกันอยู่แล้วคุณอยากจะเข้ามาเล่นเกมตอนไหนก็ได้และที่สำคัญก็ยังสามารถทำธุรกรรมต่างๆผ่านทางระบบอัตโนมัติได้ด้วยตัวเองทำให้คุณสามารถที่จะฝากเงินหรือว่าถอนเงินเข้าออกบัญชีส่วนตัวของคุณได้ตลอด
        24 ชั่วโมง
      </p>
    </>
  );
}

const mapStateToProps = (state: AppReducerStateProps) => {
  return {
    state: state,
  };
};

export default connect(mapStateToProps)(HomeContentArticle);
