import client from "../client";
import { store } from "../../store";
import { memberActionTypes } from "lib/store/member/reducer";

interface JackpotAmountRequest {
  prefix: string;
}

export default function API(request: JackpotAmountRequest): Promise<number> {
  return client
    .get(`gameinhouse/jackpot/amount`, {
      params: request,
    })
    .then((response) => response.data)
    .then((json: number) => {
      store.dispatch({
        type: memberActionTypes.ACTION_JACKPOT_DEPOSIT_UPDATE,
        payload: json,
      });
      return json;
    });
}
