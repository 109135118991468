import { store } from "lib/store";
import { memberActionTypes } from "lib/store/member/reducer";
import client from "../client";

interface Request {
  prefix: string;
}

export interface GetAllBlogsResponse {
  ID?: number;
  Title?: string;
  Desc?: string;
  CreatedAt?: Date;
  UpdateAt?: Date;
  Image?: string;
  Ref?: string;
  DisplayCreatedAt?: string;
  DisplayUpdatedAt?: string;
}

export default function API(request: Request): Promise<GetAllBlogsResponse[]> {
  return client
    .get(`content/get_all_blogs`, { params: request })
    .then((response) => response.data)
    .then((json: GetAllBlogsResponse[]) => {
      store.dispatch({
        type: memberActionTypes.ACTION_UPDATE_BLOGS,
        payload: json,
      });
      return json;
    });
}
