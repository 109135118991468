import React from "react";
import { connect } from "react-redux";
import { AppReducerStateProps } from "../../lib/store";
import { useState } from "react";
import { useEffect } from "react";
import { CheckWithPrefixResponse } from "lib/infomation_vender/document";
import { Badge, ProgressBar } from "react-bootstrap";
import get_status_member, {
  GetStatusMemberResponse,
} from "lib/worker/api/get_status_member";
import get_promotion_remaining, {
  GetPromotionRemaingingResponse,
} from "lib/worker/api/get_promotion_remaining";
import getinfo_non_r from "lib/worker/api/getinfo_non_r";
import Img from "react-cloudimage-responsive";
import Link from "next/link";
import ArrowClockWise from "components/view/arrow_clock_wise";
import Spinner from "components/view/loader";
import apigetinfo from "../../lib/worker/api/getinfo";
import start_game from "lib/worker/api/start_game";

interface HomeStatusMemberProps {
  state: AppReducerStateProps;
  infomation: CheckWithPrefixResponse;
}

function HomeStatusMemberCompoment(props: HomeStatusMemberProps) {
  const { Balance, SMPoint } = props.state.member.getinfo;
  const { getinfo } = props.state.member;
  const [isLoading, setLoading] = useState<boolean>(false);
  const [status, setStatus] = useState<GetStatusMemberResponse>();
  const [promotionsremaining, setpromotionsremaining] = useState<
    GetPromotionRemaingingResponse[]
  >([]);
  const [promotionsloading, setpromitonloading] = useState<boolean>(false);

  useEffect(() => {
    getinfoo();
  }, []);

  useEffect(() => {
    let interval = setInterval(function () {
      get_promotion_remaining().then((v) => {
        setpromotionsremaining(v);
        return;
      });
      getinfo_non_r();
    }, 5000);
    return () => {
      clearInterval(interval);
    };
  }, []);

  const getinfoo = () => {
    setLoading(true);
    setpromitonloading(true);
    get_status_member()
      .then((json) => {
        setStatus(json);
        return json;
      })
      .then(() => {
        return apigetinfo();
      })
      .finally(() => {
        setLoading(false);
      });

    get_promotion_remaining()
      .then((v) => {
        setpromotionsremaining(v);
      })
      .finally(() => {
        setpromitonloading(false);
      });
  };

  const DisplayUsername = () => {
    return (
      <div className="row col-12">
        <div>
          <div style={{ height: 16, width: 16, float: "left", marginRight: 5 }}>
            <Img
              style={{ height: 16, width: 16 }}
              src={getinfo.Rank?.Image ?? ""}
            ></Img>
          </div>
          <div className="color-font-card">
            {getinfo.Username ?? "..."}{" "}
            {status?.IsFreeCredit ? (
              <Link href="/freecredit100ufa">
                <a>
                  <div style={{ cursor: "pointer" }}>
                    <Badge bg="danger">ฟรีเครดิต</Badge>
                  </div>
                </a>
              </Link>
            ) : null}
          </div>
        </div>
      </div>
    );
  };

  return (
    <>
      <div className="row">
        <div className="col-md-10">
          <div className="card-ufa" style={{ padding: 10 }}>
            <div className="row">
              <div style={{ flex: 3, width: 200 }}>
                <div>
                  <span className="font-info" style={{ float: "left" }}>
                    ยอดเงินสด
                  </span>
                  <div style={{ clear: "both" }}></div>
                  {isLoading ? (
                    <h6
                      style={{
                        marginTop: 10,
                        fontWeight: "bold",
                        fontSize: "0.9rem",
                      }}
                    >
                      <Spinner></Spinner>
                    </h6>
                  ) : (
                    <h6
                      className="color-font-card"
                      style={{
                        marginTop: 10,
                        fontSize: "0.9rem",
                      }}
                    >
                      {Balance?.format(2, 0) ?? "0"} บาท
                    </h6>
                  )}
                </div>
              </div>

              <div
                style={{
                  width: 2,
                  padding: 0,
                  marginLeft: 1,
                  marginRight: 1,
                  backgroundColor: "#5a4725",
                }}
              ></div>
              <div style={{ flex: 7 }}>
                <div>
                  <span className="font-info">uDiamond</span>
                  {isLoading ? (
                    <h6
                      style={{
                        marginTop: 10,
                        fontWeight: "bold",
                        fontSize: "0.9rem",
                      }}
                    >
                      <Spinner></Spinner>
                    </h6>
                  ) : (
                    <h6
                      className="color-font-card"
                      style={{
                        marginTop: 10,
                        fontSize: "0.9rem",
                      }}
                    >
                      {SMPoint?.format(2, 0) ?? "0"} เพชร
                    </h6>
                  )}
                </div>
              </div>
            </div>
            <div style={{ marginTop: 5 }}>{DisplayUsername()}</div>
            <div className="row align-items-center" style={{ paddingLeft: 15 }}>
              <div
                style={{
                  marginTop: 10,

                  borderLeft: "2px solid",
                }}
              >
                <div
                  style={{
                    marginRight: 0,
                  }}
                >
                  <ArrowClockWise
                    isLoad={isLoading}
                    onClick={() => {
                      getinfoo();
                    }}
                  ></ArrowClockWise>
                </div>
                <Link href="/transaction_transfer">
                  <a style={{ textDecoration: "none" }}>
                    <div
                      className="font-info"
                      style={{ marginTop: -14, fontSize: 10, marginLeft: 20 }}
                    >
                      อัพเดตการทำรายการล่าสุดเมื่อ {getinfo.DisplayUpdatedAt}{" "}
                      ที่ผ่านมา
                    </div>
                  </a>
                </Link>
              </div>
            </div>
            <div className="row align-items-center" style={{ paddingLeft: 15 }}>
              <div
                style={{
                  marginTop: 10,
                  fontSize: 10,
                  height: 23,
                  borderLeft: "2px solid",
                }}
              >
                <div className="font-info" style={{ marginTop: -8 }}>
                  {getinfo.Rank?.Name} | EXP {getinfo.Rank?.MemberEXP} /{" "}
                  {getinfo.Rank?.RankEXP}
                </div>

                <Link href="/invite_agent">
                  <a>
                    <div
                      className="select-div font-info"
                      style={{
                        textDecoration: "underline",
                        color: "#ffffff",
                        marginTop: -15,
                      }}
                    >
                      รายละเอียด
                    </div>
                  </a>
                </Link>
              </div>
            </div>
            <div style={{ marginTop: 5 }}>
              {promotionsloading ? (
                <></>
              ) : promotionsremaining.length == 0 ? (
                <div style={{ marginTop: 0 }}>
                  <h6 className="font-info" style={{ fontSize: "0.9rem" }}></h6>
                </div>
              ) : (
                promotionsremaining.map((v) => {
                  return (
                    <div className="row" key={v.ID}>
                      <div style={{ flex: 1 }}>
                        <Badge>
                          ติดโปร | {v.RemainingAmount?.format(2, 0)} /{" "}
                          {(
                            (v.Amount ?? 0) * (v.FullFinalAmouunt ?? 0)
                          )?.format(2, 0)}
                        </Badge>
                      </div>
                      <div style={{ flex: 10 }}>
                        <ProgressBar
                          style={{ height: 20, marginTop: 3 }}
                          now={v.ProgressBar ?? 0}
                          variant="danger"
                          animated
                          label={`${v.ProgressBar?.format(0, 0)} %`}
                        />
                      </div>
                    </div>
                  );
                })
              )}
            </div>
            <div
              className="margin-top-10"
              style={{ paddingBottom: 10, width: 185, fontSize: 10 }}
            >
              <Link href="/download_app">
                <a style={{ textDecoration: "none" }}>
                  <div
                    className="btn bg-invite"
                    style={{ textDecoration: "none", fontSize: 10 }}
                  >
                    ดาวน์โหลด UFABET iOS / Android
                  </div>
                </a>
              </Link>
            </div>
          </div>
        </div>
        <div
          style={{ minHeight: 150 }}
          className="col-5 col-md-2 center-row-content row"
        >
          <div
            style={{
              outline: 0,
              minHeight: 120,
              minWidth: 120,
              width: 150,

              cursor: "pointer",
              boxShadow: "none",
              backgroundColor: "transparent",
              borderColor: "transparent",
            }}
            onClick={() => {
              start_game({
                VenderPrepix: "ufabet",
                Symbol: "ufabet",
              });
            }}
          >
            <Img src="circle-goldnew-2.png" alt="เล่นเกม UFABET"></Img>
          </div>
        </div>
      </div>
    </>
  );
}

const mapStateToProps = (state: AppReducerStateProps) => {
  return {
    state: state,
  };
};

export default connect(mapStateToProps)(HomeStatusMemberCompoment);
