import React from "react";
import Img from "react-cloudimage-responsive";
import { connect } from "react-redux";
import { AppReducerStateProps } from "../../lib/store";
import global from "lib/config/global";
import {
  CheckWithPrefixResponse,
  PREFIX_UFABETAG,
  PREFIX_UFABETAM,
} from "lib/infomation_vender/document";

interface Props {
  state: AppReducerStateProps;
  infomation: CheckWithPrefixResponse;
}

function HomeLogo(props: Props) {
  const { logo, prefix } = global().gobalVariable;

  const LogoPrefix = () => {
    switch (props.infomation.prefix) {
      case PREFIX_UFABETAM:
        return (
          <>
            <div
              style={{
                height: 110,
                marginTop: 20,
                flexDirection: "row",
                justifyContent: "center",
                display: "flex",
              }}
            >
              <div style={{ height: 220, width: 260 }}>
                <Img
                  width="340"
                  height="115"
                  src="logoagufa.png"
                  alt="ufabet am คาสิโนสด แทงบอล"
                ></Img>
              </div>
            </div>
          </>
        );
      case PREFIX_UFABETAG:
        return (
          <>
            <div
              style={{
                height: 110,
                marginTop: 20,
                flexDirection: "row",
                justifyContent: "center",
                display: "flex",
              }}
            >
              <div style={{ height: 220, width: 260 }}>
                <Img
                  width="340"
                  height="115"
                  src="logoagufa.png"
                  alt="ufabet am คาสิโนสด แทงบอล"
                ></Img>
              </div>
            </div>
          </>
        );
      default:
        return (
          <>
            <div
              style={{
                height: 80,
                marginTop: 25,
                flexDirection: "row",
                justifyContent: "center",
                display: "flex",
              }}
            >
              <div style={{ height: 200, width: 260 }}>
                <Img width="250" height="55" src={logo} alt={prefix}></Img>
              </div>
            </div>
          </>
        );
    }
  };

  return <>{LogoPrefix()}</>;
}

const mapStateToProps = (state: AppReducerStateProps) => {
  return {
    state: state,
  };
};

export default connect(mapStateToProps)(HomeLogo);
