import { MemberInitialStateProps } from "lib/store/member/reducer";
import { GetAllBlogsResponse } from "lib/worker/api/get_all_blogs";
import {
  CheckWithPrefixResponse,
  PREFIX_UFABETAG,
  PREFIX_UFAGAME,
  PREFIX_UFAKUB555,
  PREFIX_UFAONLINE1,
  PREFIX_UFCASINO7,
} from "./document";

export interface DefaultImage {
  IconMenuHomePages: IconMenuHomePages[];
  IconMenuHomeOnePages: IconMenuHomeOnePages[];
  IconCampeGames: IconCampeGames[];
  IconCampeGamesOnePages: IconCampeGamesOnePages[];
  IconUdiamondEventHomePages: IconUdiamondEventHomePages[];
  IconMenuCategoryCasino: iconMenuCategoryCasinoPages[];
  IconCampeFooter: string[];
}

export interface IconMenuHomePages {
  title: string;
  location: string;
  isDisable: boolean;
  pic: string;
  isDisableBorderBackground: boolean;
}

export interface IconMenuHomeOnePages {
  title: string;
  location: string;
  isDisable: boolean;
  pic: string;
  isDisableBorderBackground: boolean;
}

export interface IconCampeGames {
  pic: string;
  location: string;
  alt: string;
  flag: string;
  flagtitle: string;
  isEnable: boolean;
}

export interface IconCampeGamesOnePages {
  pic: string;
  location: string;
  alt: string;
  flag: string;
  flagtitle: string;
  isEnable: boolean;
  venderPrefix: string;
  isHisLobby: boolean;
}

export interface IconUdiamondEventHomePages {
  pic: string;
  bg: string;
  location: string;
  disable: boolean;
}

export interface iconMenuCategoryCasinoPages {
  pic: string;
  bg: string;
  title: string;
  location: string;
  disable: boolean;
  category: string;
}

export function iconMenuHomePagesDisplay(
  infomation: CheckWithPrefixResponse,
  member: MemberInitialStateProps,
  defaultImage: DefaultImage,
  blogs: GetAllBlogsResponse[]
): IconMenuHomePages[] {
  let newicon: IconMenuHomePages[] = defaultImage.IconMenuHomePages;
  newicon[0].isDisable = member.commission_return_to_loss == 0;
  newicon[3].isDisable = !infomation.isDealer;
  newicon[9].isDisable = blogs.length == 0;

  if (infomation.prefix == PREFIX_UFAGAME) {
    newicon[4].isDisable = infomation.prefix != PREFIX_UFAGAME;
    newicon[5].isDisable = infomation.prefix != PREFIX_UFAGAME;
  }

  if (infomation.prefix == PREFIX_UFCASINO7) {
    newicon[4].isDisable = infomation.prefix != PREFIX_UFCASINO7;
    newicon[5].isDisable = infomation.prefix != PREFIX_UFCASINO7;
  }

  if (infomation.prefix == PREFIX_UFAONLINE1) {
    newicon[4].isDisable = infomation.prefix != PREFIX_UFAONLINE1;
    newicon[5].isDisable = infomation.prefix != PREFIX_UFAONLINE1;
  }

  if (infomation.prefix == PREFIX_UFAKUB555) {
    newicon[4].isDisable = infomation.prefix != PREFIX_UFAKUB555;
    newicon[5].isDisable = infomation.prefix != PREFIX_UFAKUB555;
  }

  if (infomation.prefix == PREFIX_UFABETAG) {
    newicon[0].pic = "ag-icon3-cashback.png";
    newicon[1].pic = "ag-icon1-deposit.png";
    newicon[2].pic = "ag-icon2-withdraw.png";
    newicon[3].pic = "ag-icon6-agent.png";
    newicon[6].pic = "ag-icon5-udiamond.png";
    newicon[7].pic = "ag-icon4-history.png";
    newicon[8].pic = "ag-icon7-historyu.png";
  }

  if (infomation.prefix == PREFIX_UFABETAG) {
    newicon[0].isDisableBorderBackground = true;
    newicon[1].isDisableBorderBackground = true;
    newicon[2].isDisableBorderBackground = true;
    newicon[3].isDisableBorderBackground = true;
    newicon[6].isDisableBorderBackground = true;
    newicon[7].isDisableBorderBackground = true;
    newicon[8].isDisableBorderBackground = true;
  }

  return newicon;
}

export function iconMenuCategoryCasino(
  _information: CheckWithPrefixResponse,
  defaultImage: DefaultImage
): iconMenuCategoryCasinoPages[] {
  let newicon: iconMenuCategoryCasinoPages[] =
    defaultImage.IconMenuCategoryCasino;

  return newicon;
}
