import { store } from "lib/store";
import { memberActionTypes } from "lib/store/member/reducer";
import client from "../client";

interface Request {
  prefix?: string;
}

export default function API(request: Request): Promise<number> {
  return client
    .get(`commission/get_setting_return_to_loss_with_member`, {
      params: request,
    })
    .then((response) => response.data)
    .then((json: number) => {
      store.dispatch({
        type: memberActionTypes.ACTION_UPDATE_COMMISSION_RETURN_TO_LOSS,
        payload: json,
      });
      return json;
    });
}
