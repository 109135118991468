import { dateDiff, seconds2time, TimeState } from "lib/time";
import { useEffect, useState } from "react";

function TimerComment() {
  const calculateTimeLeft = (): TimeState => {
    let now = new Date();
    let timeLeft: TimeState = {};
    var dNextDay = new Date();
    dNextDay.setFullYear(now.getFullYear(), now.getMonth(), now.getDate() + 1);
    dNextDay.setHours(7, 0, 0, 0);
    const diff = dateDiff(
      new Date(now.getTime()),
      new Date(dNextDay.getTime())
    );
    if ((diff.minutes ?? 0) < 0) {
      timeLeft = {
        diff: 0,
        days: 0,
        hours: 0,
        minutes: 0,
        seconds: 0,
      };
    } else {
      timeLeft = {
        diff: diff.diff,
        days: diff.days,
        hours: diff.hours,
        minutes: diff.minutes,
        seconds: diff.seconds,
      };
    }

    return timeLeft;
  };

  const [timeLeft, setTimeLeft] = useState<TimeState>(calculateTimeLeft());

  useEffect(() => {
    const timer = setTimeout(() => {
      setTimeLeft(calculateTimeLeft());
    }, 1000);
    return () => clearTimeout(timer);
  });

  return (
    <div className="text-center">
      นับถอยหลังอีกเวลา {`${seconds2time(Number((timeLeft.diff ?? 0) / 1000))}`}{" "}
      นาฬิกา
    </div>
  );
}

export default TimerComment;
