interface CommentShowProps {
  User: string;
  Time: string;
  Message?: string;
  Color?: string;
}

export default function CommentShow(props: CommentShowProps) {
  return (
    <div className="container">
      <div className="">
        <div className="row">
          <div className="col-12">
            <div>
              <span>
                {props.User} โพสเมื่อ ({props.Time})
              </span>{" "}
            </div>
          </div>
        </div>
      </div>
      <div className="color-font-card">
        <div className="row">
          <div
            className="col-12 margin-top-3"
            style={{ wordBreak: "break-all" }}
          >
            {props.Message}
          </div>
        </div>
      </div>
      <div className="bottom-line-yellow"></div>
    </div>
  );
}
