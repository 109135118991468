import React from "react";
import Img from "react-cloudimage-responsive";
import { connect } from "react-redux";
import { AppReducerStateProps } from "../../lib/store";

function HomeLogoSnam() {
  return (
    <>
      <div
        style={{
          height: 80,
          marginTop: 40,
          flexDirection: "row",
          justifyContent: "center",
          display: "flex",
        }}
      >
        <div style={{ height: 200, width: 260 }}>
          <Img
            width="250"
            height="55"
            src="logo-ufabet-snam.png"
            alt="logo ufabet snam"
          ></Img>
        </div>
      </div>
    </>
  );
}

const mapStateToProps = (state: AppReducerStateProps) => {
  return {
    state: state,
  };
};

export default connect(mapStateToProps)(HomeLogoSnam);
