import get_bank_type_all, {
  GetBankTypeAllResponse,
} from "lib/worker/api/get_bank_type_all";
import { useEffect, useState } from "react";
import Img from "react-cloudimage-responsive";

function FooterSnam() {
  const [banks, setBank] = useState<GetBankTypeAllResponse[]>([]);

  useEffect(() => {
    get_bank_type_all()
      .then((json) => {
        setBank(json);
      })
      .catch((_error) => {});
  }, []);

  return (
    <div className="margin-top-20">
      <div
        className="col-12 footer_copyright center-content"
        style={{ fontSize: 12 }}
      >
        <span>ฝากถอนผ่านระบบอัตโนมัติ รวดเร็ว ทันใจ เพียง 15 วินาที</span>
      </div>
      <div className="col-12">
        <div className="row center-row-content">
          {banks.map((value, index) => {
            return index < 15 || index === 16 ? null : (
              <div
                key={index}
                className="col-2 col-md-1"
                style={{ marginTop: 10, marginBottom: 10 }}
              >
                <Img
                  ratio={1}
                  src={value.Image ?? ""}
                  alt={value.Image ?? ""}
                ></Img>
              </div>
            );
          })}
        </div>
      </div>
      <div className="text-center" style={{ fontSize: 12 }}>
        <span>Copyright © 2020 All rights reserved.</span>
      </div>
    </div>
  );
}

export default FooterSnam;
