import client from "../client";

export interface GetCommentTimeResponse {
  ID?: number;
  CreatedAt?: string;
  MemberID?: number;
}

export default function API(): Promise<GetCommentTimeResponse[]> {
  return client
    .get(`gameinhouse/comment/get_comment_time`)
    .then((response) => response.data);
}
