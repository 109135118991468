import { MaxButton } from "components/view/button";
import CheckinProgress from "components/view/checkin_progress";
import { AppReducerStateProps } from "lib/store";
import Link from "next/link";
import Img from "react-cloudimage-responsive";
import { connect } from "react-redux";

interface Props {
  state: AppReducerStateProps;
}

function HomeUgameAg(_props: Props) {
  return (
    <>
      <div className="row">
        <div className="col-12 col-md-6">
          <div className="margin-top-10">
            <div
              className="card-ufa-category border-ag"
              style={{ padding: 10 }}
            >
              <div style={{ fontSize: 18 }}>
                <span>Checking รับ uDiamond</span>
              </div>
              <div className="text-center">
                <div className="col-12 margin-top-30">
                  <div className="progress">
                    <div className="col-12 shadow" style={{ zIndex: 1 }}>
                      <CheckinProgress></CheckinProgress>
                    </div>
                  </div>
                </div>
              </div>
              <div>
                <div
                  className="margin-top-20"
                  style={{
                    width: 130,
                    marginRight: "auto",
                    marginLeft: "auto",
                  }}
                >
                  <Link href="/uGame/check_in">
                    <a style={{ textDecoration: "none" }}>
                      <MaxButton
                        title="รับ uDiamond"
                        className="margin-top-10 bg-invite"
                      ></MaxButton>
                    </a>
                  </Link>
                </div>
              </div>
              <div>
                <div className="margin-top-10 text-center">
                  <Link href="/uGame/check_in">
                    <a style={{ color: "white", textDecoration: "none" }}>
                      เช็คอินรับ uDiamond คืออะไร ได้เยอะแค่ไหน ?
                    </a>
                  </Link>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="col-12 col-md-6">
          <div className="margin-top-10">
            <div
              className="card-ufa-category border-ag"
              style={{ padding: 10 }}
            >
              <div style={{ fontSize: 18 }}>
                <span>เปิดกล่องสมบัติลุ้นรับเครดิตฟรี</span>
              </div>
              <div className="row center-row-content">
                <Link href="/uGame/open_ubox">
                  <div
                    style={{
                      height: 37,
                      width: 90,
                    }}
                    className="margin-top-10"
                  >
                    <Img src="normal-ubox-new-ufamobile.png"></Img>
                  </div>
                </Link>
              </div>
              <div>
                <div
                  className="margin-top-20"
                  style={{
                    width: 130,
                    marginRight: "auto",
                    marginLeft: "auto",
                  }}
                >
                  <Link href="/uGame/open_ubox">
                    <a style={{ textDecoration: "none" }}>
                      <MaxButton
                        title="รับ uDiamond"
                        className="margin-top-10 bg-invite"
                      ></MaxButton>
                    </a>
                  </Link>
                </div>
              </div>
              <div>
                <div className="margin-top-10 text-center">
                  <Link href="/uGame/open_ubox">
                    <a style={{ color: "white", textDecoration: "none" }}>
                      เปิดกล่องรับรางวัล คืออะไร เข้ามาดูได้เลย?
                    </a>
                  </Link>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

const mapStateToProps = (state: AppReducerStateProps) => {
  return {
    state: state,
  };
};

export default connect(mapStateToProps)(HomeUgameAg);
