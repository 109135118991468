import {
  CheckWithPrefixResponse,
  PREFIX_UFABETAG,
} from "lib/infomation_vender/document";
import { AppReducerStateProps } from "lib/store";
import start_game from "lib/worker/api/start_game";
import Img from "react-cloudimage-responsive";
import { connect } from "react-redux";

interface HomeContentAboutProps {
  infomation: CheckWithPrefixResponse;
}

function HomeContentAbout(props: HomeContentAboutProps) {
  const BannerAboutUfa = () => {
    switch (props.infomation.prefix) {
      case PREFIX_UFABETAG:
        return (
          <>
            <div className="margin-top-10">
              <div className="row col-12 margin-left-5 margin-right-5 ">
                <div
                  className="col-md-6"
                  style={{ cursor: "pointer" }}
                  onClick={() => {
                    start_game({
                      VenderPrepix: "ufabet",
                      Symbol: "ufabet",
                    });
                  }}
                >
                  <Img
                    ratio={3.8 / 1}
                    className="full-width-image margin-top-20 shine"
                    src="ag-webbannergame.png"
                    alt="CASINO ONLINE"
                  ></Img>
                </div>
                <div
                  className="col-md-6"
                  style={{ cursor: "pointer" }}
                  onClick={() => {
                    start_game({
                      VenderPrepix: "ufabet",
                      Symbol: "ufabet",
                    });
                  }}
                >
                  <Img
                    ratio={3.8 / 1}
                    className="full-width-image margin-top-20 shine"
                    src="ag-webbannersport.png"
                    alt="GAME ONLINE"
                  ></Img>
                </div>
                <div
                  className="col-md-6"
                  style={{ cursor: "pointer" }}
                  onClick={() => {
                    start_game({
                      VenderPrepix: "ufabet",
                      Symbol: "ufabet",
                    });
                  }}
                >
                  <Img
                    ratio={3.8 / 1}
                    className="full-width-image margin-top-20 shine"
                    src="ag-webbannercasino.png"
                    alt="SPORT ONLINE"
                  ></Img>
                </div>
                <div
                  className="col-md-6"
                  style={{ cursor: "pointer" }}
                  onClick={() => {
                    start_game({
                      VenderPrepix: "ufabet",
                      Symbol: "ufabet",
                    });
                  }}
                >
                  <Img
                    ratio={3.8 / 1}
                    className="full-width-image margin-top-20 shine"
                    src="ag-webbannerEsport.png"
                    alt="GAME ESPORT"
                  ></Img>
                </div>
              </div>
            </div>
          </>
        );
      default:
        return (
          <>
            <div className="margin-top-10">
              <div className="row col-12 margin-left-5 margin-right-5 ">
                <div
                  className="col-md-6"
                  style={{ cursor: "pointer" }}
                  onClick={() => {
                    start_game({
                      VenderPrepix: "ufabet",
                      Symbol: "ufabet",
                    });
                  }}
                >
                  <Img
                    ratio={3.8 / 1}
                    className="full-width-image margin-top-20 shine"
                    src="ufa-game-online.png"
                    alt="CASINO ONLINE"
                  ></Img>
                </div>
                <div
                  className="col-md-6"
                  style={{ cursor: "pointer" }}
                  onClick={() => {
                    start_game({
                      VenderPrepix: "ufabet",
                      Symbol: "ufabet",
                    });
                  }}
                >
                  <Img
                    ratio={3.8 / 1}
                    className="full-width-image margin-top-20 shine"
                    src="ufa-game-casino.png"
                    alt="GAME ONLINE"
                  ></Img>
                </div>
                <div
                  className="col-md-6"
                  style={{ cursor: "pointer" }}
                  onClick={() => {
                    start_game({
                      VenderPrepix: "ufabet",
                      Symbol: "ufabet",
                    });
                  }}
                >
                  <Img
                    ratio={3.8 / 1}
                    className="full-width-image margin-top-20 shine"
                    src="ufa-game-sport.png"
                    alt="SPORT ONLINE"
                  ></Img>
                </div>
                <div
                  className="col-md-6"
                  style={{ cursor: "pointer" }}
                  onClick={() => {
                    start_game({
                      VenderPrepix: "ufabet",
                      Symbol: "ufabet",
                    });
                  }}
                >
                  <Img
                    ratio={3.8 / 1}
                    className="full-width-image margin-top-20 shine"
                    src="ufa-game-esport.png"
                    alt="GAME ESPORT"
                  ></Img>
                </div>
              </div>
            </div>
          </>
        );
    }
  };

  return <>{BannerAboutUfa()}</>;
}

const mapStateToProps = (state: AppReducerStateProps) => {
  return {
    state: state,
  };
};

export default connect(mapStateToProps)(HomeContentAbout);
