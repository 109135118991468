import Img from "react-cloudimage-responsive";
import Link from "next/link";
import {
  CheckWithPrefixResponse,
  PREFIX_SNAMBET,
  PREFIX_UF9Pro,
  PREFIX_UFABER1,
  PREFIX_UFABETAG,
  PREFIX_UFABETAM,
  PREFIX_UFAFUNVIP,
  PREFIX_UFAGAME,
  PREFIX_UFAKUB555,
  PREFIX_UFAMM,
  PREFIX_UFAONLINE1,
  PREFIX_UFCASINO7,
} from "lib/infomation_vender/document";

interface Props {
  infomation: CheckWithPrefixResponse;
}

function HomeInviteFriend(props: Props) {
  const BannerFriend = () => {
    switch (props.infomation.prefix) {
      case PREFIX_UFABER1:
        return (
          <>
            <Link href="invite_agent">
              <a>
                <div className="margin-top-10 margin-bottom-20 select-div">
                  <Img
                    className="shine"
                    ratio={3.2 / 1}
                    src="ufaber1-banner1.png"
                    alt="UFABET1 แนะนำเพื่อนรับ uDiamond"
                  ></Img>
                </div>
              </a>
            </Link>
          </>
        );
      case PREFIX_UFABETAM:
        return (
          <>
            <Link href="invite_agent">
              <a>
                <div className="margin-top-10 margin-bottom-20 select-div">
                  <Img
                    className="shine"
                    ratio={3.2 / 1}
                    src="ufabetam-friend.png"
                    alt="UFABETAM แนะนำเพื่อนรับ uDiamond"
                  ></Img>
                </div>
              </a>
            </Link>
          </>
        );
      case PREFIX_SNAMBET:
        return (
          <>
            <Link href="invite_agent">
              <a>
                <div className="margin-top-10 margin-bottom-20 select-div">
                  <Img
                    className="shine"
                    ratio={3.2 / 1}
                    src="ufabetsnam-banner-invite.png"
                    alt="ufabet snam แนะนำเพื่อนรับ uDiamond"
                  ></Img>
                </div>
              </a>
            </Link>
          </>
        );
      case PREFIX_UFAKUB555:
        return (
          <>
            <Link href="invite_agent">
              <a>
                <div className="margin-top-10 margin-bottom-20 select-div">
                  <Img
                    className="shine"
                    ratio={3.2 / 1}
                    src="banner-ufakub555-invite.png"
                    alt="ufabet snam แนะนำเพื่อนรับ uDiamond"
                  ></Img>
                </div>
              </a>
            </Link>
          </>
        );
      case PREFIX_UFAGAME:
        return (
          <>
            <Link href="invite_agent">
              <a>
                <div className="margin-top-10 margin-bottom-20 select-div">
                  <Img
                    className="shine"
                    ratio={3.2 / 1}
                    src="1248x428-game-ufagame.jpg"
                    alt="ufabet snam แนะนำเพื่อนรับ uDiamond"
                  ></Img>
                </div>
              </a>
            </Link>
          </>
        );
      case PREFIX_UFAONLINE1:
        return (
          <>
            <Link href="invite_agent">
              <a>
                <div className="margin-top-10 margin-bottom-20 select-div">
                  <Img
                    className="shine"
                    ratio={3.2 / 1}
                    src="1248x428-3-ufaonline1.jpg"
                    alt="ufabet snam แนะนำเพื่อนรับ uDiamond"
                  ></Img>
                </div>
              </a>
            </Link>
          </>
        );
      case PREFIX_UFABETAG:
        return (
          <>
            <Link href="invite_agent">
              <a>
                <div className="margin-top-10 margin-bottom-20 select-div">
                  <Img
                    className="shine"
                    ratio={3.2 / 1}
                    src="ag-webbanner007.png"
                    alt="ufabet ag แนะนำเพื่อนรับ uDiamond"
                  ></Img>
                </div>
              </a>
            </Link>
          </>
        );
      case PREFIX_UFCASINO7:
        return (
          <>
            <Link href="invite_agent">
              <a>
                <div className="margin-top-10 margin-bottom-20 select-div">
                  <Img
                    className="shine"
                    ratio={3.2 / 1}
                    src="1248x428-1.jpg"
                    alt="ufabet snam แนะนำเพื่อนรับ uDiamond"
                  ></Img>
                </div>
              </a>
            </Link>
          </>
        );
      case PREFIX_UF9Pro:
        return (
          <>
            <Link href="invite_agent">
              <a>
                <div className="margin-top-10 margin-bottom-20 select-div">
                  <Img
                    className="shine"
                    ratio={3.2 / 1}
                    src="uf9pro-b3.png"
                    alt="ufabet แนะนำเพื่อนรับ uDiamond"
                  ></Img>
                </div>
              </a>
            </Link>
          </>
        );
      case PREFIX_UFAMM:
        return (
          <>
            <Link href="invite_agent">
              <a>
                <div className="margin-top-10 margin-bottom-20 select-div">
                  <Img
                    className="shine"
                    ratio={3.2 / 1}
                    src="ufabetsnam-banner-invite.png"
                    alt="ufabet snam แนะนำเพื่อนรับ uDiamond"
                  ></Img>
                </div>
              </a>
            </Link>
          </>
        );
      case PREFIX_UFAFUNVIP:
        return (
          <>
            <Link href="invite_agent">
              <a>
                <div className="margin-top-10 margin-bottom-20 select-div">
                  <Img
                    className="shine"
                    ratio={3.2 / 1}
                    src="ufabetsnam-banner-invite.png"
                    alt="ufabet snam แนะนำเพื่อนรับ uDiamond"
                  ></Img>
                </div>
              </a>
            </Link>
          </>
        );
      default:
        return <></>;
    }
  };
  return (
    <div>
      {BannerFriend()}
      {/*  <h4>
        <span>แนะนำเพื่อนรับค่าคอมมิชชั่นทันที</span>
      </h4>
      <p>
        <span>สร้างลิงค์ชวนเพื่อน</span>
        มาเล่น รับทันทีค่า <span>คอมมิชชั่นจากufabet</span>{" "}
        ท่านสามารถรับค่าคอมมิชชั่นได้จากด้านล่าง การันตีรายได้
        ยิ่งชวนเยอะยิ่งได้มาก ยอดจากเกมต่างๆ แทงบอล คาสิโนออนไลน์ ไฮโล
        และเกมอื่นๆอีกมากมายบนโปรกเกอร์
      </p> */}
    </div>
  );
}

export default HomeInviteFriend;
