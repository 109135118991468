import { AppReducerStateProps } from "lib/store";
import { connect } from "react-redux";
import FooterSnam from "../view/footer_snam";
import React from "react";
import HomeMenuSectionComponent from "components/home/home_menu_selection";
import { GetVenderDomainInfomationResponse } from "lib/worker/api/get_vender_domain_infomation";
import { GetAllContentByPrefixResponse } from "lib/worker/api/get_all_content_by_prefix";
import { CheckWithPrefixResponse } from "lib/infomation_vender/document";
import { GetPromotionTypesResponse } from "lib/worker/api/get_promotion_types";
import HomeBannerComponent from "./home_banner";
import HomeLogo from "./home_logo";
import HomeJackpot from "./home_jackpot";
import HomeStatusMemberCompoment from "./home_status_member";
import HomeInviteFriend from "./home_invite_friend";
import HomeContentAbout from "./home_content_about";
import HomeContentArticle from "./home_content_article";
import HomeUgameAg from "./home_ugame_ag";
import HomeCommentAg from "./home_comment_ag";
import HomeContentUfabetAg from "./home_content_ufabet_ag";

interface HomeUfaBetAgProps {
  state: AppReducerStateProps;
  venderInfomation: GetVenderDomainInfomationResponse;
  content: GetAllContentByPrefixResponse[];
  infomation: CheckWithPrefixResponse;
  promotions: GetPromotionTypesResponse[];
}

function HomeUfaBetAg(_props: HomeUfaBetAgProps) {
  const { isLogin } = _props.state.member;

  return (
    <>
      <div className="container" style={{ paddingTop: 5 }}>
        <HomeLogo infomation={_props.infomation}></HomeLogo>
      </div>

      <div className="container">
        {!isLogin && (
          <div className="margin-top-10 margin-bottom-10">
            <HomeBannerComponent
              infomation={_props.infomation}
            ></HomeBannerComponent>
          </div>
        )}
      </div>

      <div className="container">
        {isLogin && (
          <>
            <HomeStatusMemberCompoment
              infomation={_props.infomation}
            ></HomeStatusMemberCompoment>
          </>
        )}
      </div>

      <div className="container">
        <HomeMenuSectionComponent
          isLoading={false}
          infomation={_props.infomation}
        ></HomeMenuSectionComponent>
      </div>
      <div className="container">
        <HomeJackpot information={_props.infomation}></HomeJackpot>
      </div>
      <div className="container margin-top-10">
        <HomeUgameAg></HomeUgameAg>
      </div>

      {!isLogin && (
        <div className="margin-top-10 margin-bottom-10 container">
          <HomeContentArticle
            infomation={_props.infomation}
          ></HomeContentArticle>
        </div>
      )}

      <div className="container margin-top-20">
        <HomeInviteFriend infomation={_props.infomation}></HomeInviteFriend>
      </div>

      <div className="container">
        <HomeCommentAg infomation={_props.infomation}></HomeCommentAg>
      </div>

      <div className="container">
        <HomeContentAbout infomation={_props.infomation}></HomeContentAbout>
      </div>

      <div className="container">
        <HomeContentUfabetAg></HomeContentUfabetAg>
      </div>

      <div className="container">
        <FooterSnam></FooterSnam>
      </div>
    </>
  );
}

const mapStateToProps = (state: AppReducerStateProps) => {
  return {
    state: state,
  };
};

export default connect(mapStateToProps)(HomeUfaBetAg);
