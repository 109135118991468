import { CheckWithPrefixResponse } from "lib/infomation_vender/document";
import { AppReducerStateProps } from "lib/store";
import Img from "react-cloudimage-responsive";
import { connect } from "react-redux";

interface Props {
  infomation: CheckWithPrefixResponse;
}

function HomeBannerSnam(_props: Props) {
  return (
    <>
      <h3 className="text-center" style={{ fontSize: 30 }}>
        <span>แทงบอลออนไลน์ UFABET</span>
      </h3>
      <h2 className="text-center" style={{ fontSize: 30 }}>
        <span>ยูฟ่าเบท | UFABET.SNAM</span>
      </h2>

      <Img
        className="margin-top-10 shine"
        ratio={3.2}
        src="ufabetsnam-banner-deposit.png"
        alt="ufabet snam ฝาก ถอน โอนไว"
      ></Img>
      <Img
        className="margin-top-10 shine"
        ratio={3.2}
        src="ufabetsnam-banner-download.png"
        alt="ufabet snam ดาวน์โหลด"
      ></Img>
    </>
  );
}

const mapStateToProps = (state: AppReducerStateProps) => {
  return {
    state: state,
  };
};

export default connect(mapStateToProps)(HomeBannerSnam);
