import client from "../client";

export interface GetCommentRequest {
  prefix?: string;
  start?: number;
  limit?: number;
}

export interface GetCommentResponse {
  ID?: number;
  Games?: number;
  HashKey?: string;
  Username?: string;
  CreatedAt?: Date;
  UpdatedAt?: Date;
  Comment?: string;
  DisplayCreatedAt?: string;
  DisplayUpdatedAt?: string;
}

export default function API(
  request: GetCommentRequest
): Promise<GetCommentResponse[]> {
  return client
    .get(`gameinhouse/comment/get_comment`, { params: request })
    .then((response) => response.data);
}
