import client from "../client";
import { GetPromotionTypesResponse } from "./get_promotion_types";

interface GetPromotionAvaliableForMemberRequest {
  token?: string;
  prefix: string;
}

export default function API(
  request: GetPromotionAvaliableForMemberRequest
): Promise<GetPromotionTypesResponse[]> {
  return client
    .get(`members/get_promotion_available_for_member`, {
      headers: {
        Authorization: `Bearer ${request?.token ?? ""}`,
      },
      params: request,
    })
    .then((response) => response.data)
    .then((json) => {
      return json;
    });
}

export function api_get_promotion_available_for_member(
  request: GetPromotionAvaliableForMemberRequest
): Promise<GetPromotionTypesResponse[]> {
  return client
    .get(`members/get_promotion_available_for_member`, {
      params: request,
    })
    .then((response) => response.data)
    .then((json) => {
      return json;
    });
}
