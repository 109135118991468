import { connect } from "react-redux";
import { AppReducerStateProps } from "../../lib/store";
import Link from "next/link";
import Img from "react-cloudimage-responsive";
import { CheckWithPrefixResponse } from "lib/infomation_vender/document";
import { useEffect, useState } from "react";
import get_setting_return_to_loss_with_member from "lib/worker/api/get_setting_return_to_loss_with_member";
import get_all_blogs, {
  GetAllBlogsResponse,
} from "lib/worker/api/get_all_blogs";
import { iconMenuHomePagesDisplay } from "lib/infomation_vender/images_for_vender";
import { alertWithDeposit } from "components/modal/deposit_modal";
import { alertWithWithDraw } from "components/modal/withdraw_modal";

interface HomeMenuSectionComponentProps {
  state: AppReducerStateProps;
  isLoading: boolean;
  infomation: CheckWithPrefixResponse;
}

function HomeMenuSectionComponent(_props: HomeMenuSectionComponentProps) {
  const [blogs, setblogs] = useState<GetAllBlogsResponse[]>([]);

  useEffect(() => {
    get_all_blogs({ prefix: _props.infomation.prefix }).then(setblogs);
  }, []);

  useEffect(() => {
    get_setting_return_to_loss_with_member({
      prefix: _props.infomation.prefix,
    });
  }, []);

  return (
    <>
      <div className="wrapper row center-section" style={{ marginBottom: 10 }}>
        {iconMenuHomePagesDisplay(
          _props.infomation,
          _props.state.member,
          _props.infomation.defaultImage,
          blogs
        )
          .filter((v) => !v.isDisable)
          .map((value, i) => {
            if (value.title == "ฝากเงิน") {
              return (
                <div
                  key={i}
                  className="col-4 col-md-2"
                  style={{
                    cursor: "pointer",
                    alignItems: "center",
                    justifyContent: "center",
                    marginTop: 10,
                  }}
                  onClick={() => {
                    alertWithDeposit();
                  }}
                >
                  <div
                    style={{
                      marginTop: 5,
                    }}
                    className={
                      !value.isDisableBorderBackground
                        ? "center-row-content card-ufa-category border-ufa"
                        : "center-row-content "
                    }
                  >
                    <div
                      className={
                        !value.isDisableBorderBackground
                          ? "center-row-content icon-menu"
                          : "center-row-content "
                      }
                    >
                      <div style={{ padding: "5px 5px 5px 5px" }}>
                        <Img
                          ratio={1 / 1}
                          src={value.pic ?? ""}
                          alt={value.pic}
                        ></Img>
                      </div>
                    </div>
                    {!value.isDisableBorderBackground ? (
                      <div
                        className="text-center font-span"
                        style={{ fontSize: 12 }}
                      >
                        {value.title}
                      </div>
                    ) : (
                      <></>
                    )}
                  </div>
                </div>
              );
            }

            if (value.title == "ถอนเงิน") {
              return (
                <div
                  key={i}
                  className="col-4 col-md-2"
                  style={{
                    cursor: "pointer",
                    alignItems: "center",
                    justifyContent: "center",
                    marginTop: 10,
                  }}
                  onClick={() => {
                    alertWithWithDraw();
                  }}
                >
                  <div
                    style={{
                      marginTop: 5,
                    }}
                    className={
                      !value.isDisableBorderBackground
                        ? "center-row-content card-ufa-category border-ufa"
                        : "center-row-content "
                    }
                  >
                    <div
                      className={
                        !value.isDisableBorderBackground
                          ? "center-row-content icon-menu"
                          : "center-row-content "
                      }
                    >
                      <div style={{ padding: "5px 5px 5px 5px" }}>
                        <Img
                          ratio={1 / 1}
                          src={value.pic ?? ""}
                          alt={value.pic}
                        ></Img>
                      </div>
                    </div>
                    {!value.isDisableBorderBackground ? (
                      <div
                        className="text-center font-span"
                        style={{ fontSize: 12 }}
                      >
                        {value.title}
                      </div>
                    ) : (
                      <></>
                    )}
                  </div>
                </div>
              );
            }

            return (
              <>
                <div
                  key={i}
                  className="col-4 col-md-2"
                  style={{
                    cursor: "pointer",
                    alignItems: "center",
                    justifyContent: "center",
                    marginTop: 10,
                  }}
                >
                  <Link href={value.location}>
                    <a style={{ textDecoration: "none" }}>
                      <div
                        style={{
                          marginTop: 5,
                        }}
                        className={
                          !value.isDisableBorderBackground
                            ? "center-row-content card-ufa-category border-ufa"
                            : "center-row-content "
                        }
                      >
                        <div
                          className={
                            !value.isDisableBorderBackground
                              ? "center-row-content icon-menu"
                              : "center-row-content "
                          }
                        >
                          <div style={{ padding: "5px 5px 5px 5px" }}>
                            <Img
                              ratio={1 / 1}
                              src={value.pic ?? ""}
                              alt={value.pic}
                            ></Img>
                          </div>
                        </div>
                        {!value.isDisableBorderBackground ? (
                          <div
                            className="text-center font-span"
                            style={{ fontSize: 12 }}
                          >
                            {value.title}
                          </div>
                        ) : (
                          <></>
                        )}
                      </div>
                    </a>
                  </Link>
                </div>
              </>
            );
          })}
      </div>
    </>
  );
}

const mapStateToProps = (state: AppReducerStateProps) => {
  return {
    state: state,
  };
};

export default connect(mapStateToProps)(HomeMenuSectionComponent);
