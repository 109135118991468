import client from "../client";

export interface CheckTimeResponse {
  is_reward?: boolean;
  message?: string;
  MessageTime?: null;
  TimeAt?: string | undefined;
  amount?: number | undefined;
}

export default function API(): Promise<CheckTimeResponse> {
  return client
    .get(`gameinhouse/checkin/check_time`)
    .then((response) => response.data);
}
