interface TitleSpacerProps {
  Title?: string | undefined;
}

export default function TitleSpacer(props: TitleSpacerProps) {
  return (
    <div className="row row_with_dash">
      <div className="col-12">
        <div className="title-box">
          <div className="text-title">
            <span>{props.Title}</span>
          </div>
        </div>
        <div className="view-background-spacer"></div>
      </div>
    </div>
  );
}
