import { MouseEventHandler } from "react";

interface ArrowClockWiseProps {
  isLoad: boolean | undefined;
  onClick: MouseEventHandler<HTMLDivElement> | undefined;
}

export default function ArrowClockWise(props: ArrowClockWiseProps) {
  const { isLoad, onClick } = props;
  return (
    <div style={{ height: 15, width: 15, marginTop: -5 }} onClick={onClick}>
      <img
        src="assets/synchronization-arrows.svg"
        className={` arrow-clock-spinner-size ${
          isLoad && "arrow-clock-spinner"
        } ${!isLoad && "select-div"} `}
      ></img>
    </div>
  );
}
